import styled, {createGlobalStyle} from 'styled-components';

export const GlobalStylePage = createGlobalStyle`
  @media(min-width: 300px) and (max-width: 767px){
    .img-obra{
      width: 100%;
      height: auto;
    }
    .speech-bubble-mini{
      margin-top: -315px;
      margin-left: 100px;
    }
  }
  @media(min-width: 768px) and (max-width: 1023px){
    .img-obra{
      width: 615px;
      height: 385px;
    }
    .speech-bubble-mini{
      margin-top: -410px;
      margin-left: 265px;
    }
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    .img-obra{
      width: 100%;
    }
    .speech-bubble-mini{
      margin-top: -540px;
      margin-left: 380px;
    }
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    .img-obra{
      width: 100%;
    }
    .speech-bubble-mini{
      margin-top: -135px;
      margin-left: 470px;
    }
  }
  @media(min-width: 1408px) and (max-width: 1919px){
    .img-obra{
      width: 100%;
    }
    .speech-bubble-mini{
      margin-top: -160px;
      margin-left: 520px;
    }
  }
  @media(min-width: 1920px){
    .img-obra{
      width: 100%;
    }
    .speech-bubble-mini{
      margin-top: -320px;
      margin-left: 730px;
    }
  }
`
export const ContArrow = styled.div`
  width: 100%;
  height: 40px;
  z-index: 999;
`
export const ContImgHombreVolando = styled.div`
  z-index: 99;
  
  @media(min-width: 300px) and (max-width: 767px){
    width: 106px;
    height: auto;
    margin-top: -183px;
    margin-left: 110px;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    width: 220px;
    height: auto;
    margin-top: -109px;
    margin-left: 243px;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    width: 240px;
    height: auto;
    margin-top: -188px;
    margin-left: 278px;
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    width: 240px;
    height: auto;
    margin-top: 109px;
    margin-left: 300px;
  }
  @media(min-width: 1408px) and (max-width: 1919px){
    width: 300px;
    height: auto;
    margin-top: 115px;
    margin-left: 350px;
  }
  @media(min-width: 1920px){
    width: 370px;
    height: auto;
    margin-top: 85px;
    margin-left: 565px;
  }
`
export const ImgHombreVolando = styled.img`
  width: 100%;
  height: 100%;
`
export const ContImgHombreOlas = styled.div`
  z-index: 99;
  
  @media(min-width: 300px) and (max-width: 767px){
    width: 70px;
    height: auto;
    margin-top: -215px;
    margin-left: -160px;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    width: 120px;
    height: auto;
    margin-top: -160px;
    margin-left: -202px;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    width: 160px;
    height: auto;
    margin-top: -235px;
    margin-left: -235px;
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    width: 160px;
    height: auto;
    margin-top: 165px;
    margin-left: -220px;
  }
  @media(min-width: 1408px) and (max-width: 1919px){
    width: 200px;
    height: auto;
    margin-top: 150px;
    margin-left: -270px;
  }
  @media(min-width: 1920px){
    width: 270px;
    height: auto;
    margin-top: 210px;
    margin-left: -350px;
  }
`
export const ImgHombreOlas = styled.img`
  width: 100%;
  height: 100%;
`
export const ContImgHombreParado = styled.div`
  height: auto;
  z-index: 99;

  @media(min-width: 300px) and (max-width: 767px){
    width: 50px;
    margin-top: -232px;
    margin-left: -185px;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    width: 110px;
    margin-top: -210px;
    margin-left: -385px;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    width: 140px;
    margin-top: -283px;
    margin-left: -475px;
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    width: 160px;
    margin-top: 105px;
    margin-left: -550px;
  }
  @media(min-width: 1408px) and (max-width: 1919px){
    width: 180px;
    margin-top: 86px;
    margin-left: -650px;
  }
  @media(min-width: 1920px){
    width: 260px;
    margin-top: 105px;
    margin-left: -868px;
  }
`
export const ImgHombreParado = styled.img`
  width: 100%;
  height: 100%;
`
export const ContImgHombreParado2 = styled.div`
  height: auto;
  z-index: 99;

  @media(min-width: 300px) and (max-width: 767px){
    width: 40px;
    margin-top: -285px;
    margin-left: 100px;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    width: 80px;
    margin-top: -330px;
    margin-left: 220px;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    width: 100px;
    margin-top: -430px;
    margin-left: 320px;
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    width: 110px;
    margin-top: -130px;
    margin-left: 420px;
  }
  @media(min-width: 1408px) and (max-width: 1919px){
    width: 120px;
    margin-top: -160px;
    margin-left: 460px;
  }
  @media(min-width: 1920px){
    width: 170px;
    margin-top: -235px;
    margin-left: 600px;
  }
`
export const ImgHombreParado2 = styled.img`
  width: 100%;
  height: 100%;
`

export const ContImgCabeza = styled.div`
  z-index: 99;

  @media(min-width: 300px) and (max-width: 767px){
    width: 50px;
    height: auto;
    margin-top: -220px;
    margin-left: 60px;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    width: 110px;
    height: auto;
    margin-top: -180px;
    margin-left: 135px;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    width: 140px;
    height: auto;
    margin-top: -283px;
    margin-left: 190px;
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    width: 160px;
    height: auto;
    margin-top: 105px;
    margin-left: 225px;
  }
  @media(min-width: 1408px) and (max-width: 1919px){
    width: 180px;
    height: auto;
    margin-top: 86px;
    margin-left: 250px;
  }
  @media(min-width: 1920px){
    width: 260px;
    height: auto;
    margin-top: 105px;
    margin-left: 360px;
  }
`
export const ImgCabeza = styled.img`
  width: 100%;
  height: 100%;
`
