import React, {useEffect, useState} from 'react';
import {useStaticQuery, graphql} from 'gatsby';
import Img from 'gatsby-image';
import {Column, Columns} from 'bloomer';
import {ContObraComp} from '../../ContObra';
import {GlobalStylePage, ContArrow, ContImgHombreVolando, ImgHombreVolando, ContImgHombreOlas, ImgHombreOlas, ContImgHombreParado, ImgHombreParado, ContImgCabeza, ImgCabeza, ContImgHombreParado2, ImgHombreParado2} from './styles';
import {ArrowNavTop, ArrowNavRight, ArrowNavBottom, ArrowNavLeft} from '../../ArrowsNavigation';
import {GlobalStyle} from '../../../styles/GlobalStyles';
import {SpeechBubbleMini} from '../../SpeechBubbles/SpeechBubbleMini';
import {SpeechBubble} from '../../SpeechBubbles';
import useSound from 'use-sound';
import {gsap} from "gsap";
import imgHombreVolando from '../../../images/obras/obra-8/hombre-volando.png';
import imgHombreOlas from '../../../images/obras/obra-8/hombre-olas.png';
import imgHombreParado from '../../../images/obras/obra-8/hombre-parado.png';
import imgHombreParado2 from '../../../images/obras/obra-8/hombre-parado-2.png';
import imgCabeza from '../../../images/obras/obra-8/cabeza.png';
import soundHombreVolando from '../../../images/obras/obra-8/carreta.mp3';
import soundOlas from '../../../images/obras/obra-8/olas.mp3';
import {BtnReplay} from '../../BtnReplay';
import {BtnParrilla} from '../../BtnParrilla';

const arNav = {
  top: null,
  right: 9,
  bottom: null,
  left: 7,
};

const Page08Comp = () => {
  const [showArrows, setShowArrows] = useState(false);
  const [initSpeechBubbleMini, setInitSpeechBubbleMini] = useState(false);
  const [initSpeechBubble, setInitSpeechBubble] = useState(false);
  const [initAnimatedHombreVolando, setInitAnimatedHombreVolando] = useState(false);
  const [initAnimatedHombreOlas, setInitAnimatedHombreOlas] = useState(false);
  const [initAnimatedHombreParado, setInitAnimatedHombreParado] = useState(false);
  const [initAnimatedHombreParado2, setInitAnimatedHombreParado2] = useState(false);
  const [initAnimatedCabeza, setInitAnimatedCabeza] = useState(false);
  const [initsoundHombreVolando, setInitsoundHombreVolando] = useState(false);
  const [initsoundOlas, setInitsoundOlas] = useState(false);
  const [reLaunchAll, setReLaunchAll] = useState(false);

  /*const data = useStaticQuery(graphql`
    {
      file(relativePath: {eq: "obras/obra-1/obra.jpeg"}) {
        childImageSharp {
          fixed(width: 340, height: 670) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);*/
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "obras/obra-8/obra.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 1200, maxHeight: 750) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const mouseOver = () => {
    setShowArrows(true);
  };
  const mouseLeaver = () => {
    setShowArrows(false);
  };

  const fnReLaunchAll = () => {
    fnReLaunchHombreVolando(0);
    fnReLaunchHombreOlas(0);
    fnReLaunchHombreParado(0);
    fnReLaunchHombreParado2(0);
    fnReLaunchCabeza(0);
    setReLaunchAll(!reLaunchAll);
  }

  const fnReLaunchHombreVolando = (noReplay = 1) => {
    setInitAnimatedHombreVolando(false);
    setInitsoundHombreVolando(false);

    if(noReplay) {
      setTimeout(function () {
        setInitAnimatedHombreVolando(true);
      }, 1000);
    }
  };
  const fnReLaunchHombreOlas = (noReplay = 1) => {
    setInitAnimatedHombreOlas(false);
    setInitsoundOlas(false);

    if(noReplay) {
      setTimeout(function () {
        setInitAnimatedHombreOlas(true);
      }, 1000);
    }
  };
  const fnReLaunchHombreParado = (noReplay = 1) => {
    setInitAnimatedHombreParado(false);

    if(noReplay) {
      setTimeout(function () {
        setInitAnimatedHombreParado(true);
      }, 1000);
    }
  };
  const fnReLaunchHombreParado2 = (noReplay = 1) => {
    setInitAnimatedHombreParado2(false);

    if(noReplay) {
      setTimeout(function () {
        setInitAnimatedHombreParado2(true);
      }, 1000);
    }
  };
  const fnReLaunchCabeza = (noReplay = 1) => {
    setInitAnimatedCabeza(false);

    if(noReplay) {
      setTimeout(function () {
        setInitAnimatedCabeza(true);
      }, 1000);
    }
  };

  const fnAnimateHombreVolando = () => {
    return (
      <ContImgHombreVolando className={'img-animated'} onClick={() => fnReLaunchHombreVolando()}>
        {
          initAnimatedHombreVolando &&
          <ImgHombreVolando
            id={'animate-hombre-volando'}
            className={'animate-hombre-volando'}
            src={imgHombreVolando}
            alt="Hombre"
          />
        }
      </ContImgHombreVolando>
    )
  }
  const fnAnimateHombreOlas = () => {
    return (
      <ContImgHombreOlas className={'img-animated'} onClick={() => fnReLaunchHombreOlas()}>
        {
          initAnimatedHombreOlas &&
          <ImgHombreOlas
            id={'animate-hombre-olas'}
            className={'animate-hombre-olas'}
            src={imgHombreOlas}
            alt="Hombre"
          />
        }
      </ContImgHombreOlas>
    )
  }
  const fnAnimateHombreParado = () => {
    return (
      <ContImgHombreParado className={'img-animated'} onClick={() => fnReLaunchHombreParado()}>
        {
          initAnimatedHombreParado &&
          <ImgHombreParado
            id={'animate-hombre-parado'}
            className={'animate-hombre-parado'}
            src={imgHombreParado}
            alt="Hombre Parado"
          />
        }
      </ContImgHombreParado>
    )
  }
  const fnAnimateHombreParado2 = () => {
    return (
      <ContImgHombreParado2 className={'img-animated'} onClick={() => fnReLaunchHombreParado2()}>
        {
          initAnimatedHombreParado2 &&
          <ImgHombreParado2
            id={'animate-hombre-parado-2'}
            className={'animate-hombre-parado-2'}
            src={imgHombreParado2}
            alt="Hombre Parado"
          />
        }
      </ContImgHombreParado2>
    )
  }
  const fnAnimateCabeza = () => {
    return (
      <ContImgCabeza className={'img-animated'} onClick={() => fnReLaunchCabeza()}>
        {
          initAnimatedCabeza &&
          <ImgCabeza
            id={'animate-cabeza'}
            className={'animate-cabeza'}
            src={imgCabeza}
            alt="Cabeza"
          />
        }
      </ContImgCabeza>
    )
  }

  const fnSpeechBubbleMini = () => {
    return (
      <>
        {
          initSpeechBubbleMini &&
          <SpeechBubbleMini
            id={'speech-bubble-mini'}
            className={'speech-bubble-mini'}
            onClick={() => setInitSpeechBubble(true)}
          />
        }
      </>
    )
  }
  const fnSpeechBubble = () => {
    return (
      <>
        {
          initSpeechBubble &&
          <SpeechBubble
            id={'speech-bubble'}
            className={''}
            classClose={'close-top-left'}
            onClick={() => setInitSpeechBubble(false)}
          >
            <div> La primavera a mediados del invierno es su propia estación.</div>
            <div>Sempiterna, aunque empapada hacia la puesta del sol.</div>
          </SpeechBubble>
        }
      </>
    )
  }

  const [playHombreVolando, { stopHombreVolando }] = useSound(
    soundHombreVolando,
    { volume: 0.3 }
  );
  const [playOlas, { stopOlas }] = useSound(
    soundOlas,
    { volume: 0.3 }
  );

  useEffect(() => {
    setTimeout(function (){
      setInitAnimatedHombreVolando(true);
    }, 3000);

    setTimeout(function (){
      setInitAnimatedHombreOlas(true);
    }, 6000);

    setTimeout(function (){
      setInitAnimatedHombreParado(true);
    }, 9000);

    setTimeout(function (){
      setInitAnimatedHombreParado2(true);
    }, 9000);

    setTimeout(function (){
      setInitAnimatedCabeza(true);
    }, 12000);

    setTimeout(function (){
      setInitSpeechBubbleMini(true);
    }, 15000);
  },[reLaunchAll]);

  useEffect(() => {
    const windowWrap = gsap.utils.wrap(0, window.innerWidth);
    const winWidth = window.innerWidth;
    let arPosMenFly = {};
    let arPosMenWaves = {};
    let arPosStandingMan = {};

    if(winWidth < 767){
      arPosMenFly = {x: -120, y: -120}
      arPosMenWaves = {x: 120}
      arPosStandingMan = {x: 60}
    } else if(winWidth < 1023){
      arPosMenFly = {x: -250, y: -250}
      arPosMenWaves = {x: 120}
      arPosStandingMan = {x: 120}
    } else if(winWidth < 1215){
      arPosMenFly = {x: -300, y: -330}
      arPosMenWaves = {x: 120}
      arPosStandingMan = {x: 120}
    } else if(winWidth < 1407){
      arPosMenFly = {x: -300, y: -300}
      arPosMenWaves = {x: 50}
      arPosStandingMan = {x: 100}
    } else if(winWidth < 1919){
      arPosMenFly = {x: -400, y: -400}
      arPosMenWaves = {x: 60}
      arPosStandingMan = {x: 140}
    } else {
      arPosMenFly = {x: -600, y: -450}
      arPosMenWaves = {x: 70}
      arPosStandingMan = {x: 180}
    }

    if(initAnimatedHombreVolando){
      /*gsap.fromTo(".animate-hombre-volando", 2, {
        x: 800,
        y: 800
      }, {
        x: 0,
        y: 0
      }, "first");*/
      gsap.to(".animate-hombre-volando", {
        x: arPosMenFly.x,
        y: arPosMenFly.y,
        duration: 3,
        delay: 0,
      });

      if(!initsoundHombreVolando){
        playHombreVolando();
        setInitsoundHombreVolando(true);
      }
    }

    if(initAnimatedHombreOlas){
      /*gsap.fromTo(".animate-hombre-olas", 2, {
        x: -80,
        opacity: 0,
      }, {
        x: 0,
        opacity: 1,
      }, "second");*/
      gsap.to(".animate-hombre-olas", {
        x: arPosMenWaves.x,
        duration: 3,
        delay: 0,
        opacity: 1,
      });

      if(!initsoundOlas){
        setTimeout(function(){
          playOlas();
          setInitsoundOlas(true);
        }, 2000);
      }
    }

    if(initAnimatedHombreParado){
      gsap.to(".animate-hombre-parado", {
        duration: 2,
        delay: 0,
      });
      gsap.to(".animate-hombre-parado", {
        x: arPosStandingMan.x,
        duration: 3,
        delay: 1,
      });
    }

    if(initAnimatedHombreParado2){
      gsap.to(".animate-hombre-parado-2", {
        duration: 2,
        delay: 0,
      });
      gsap.to(".animate-hombre-parado-2", {
        duration: 3,
        delay: 1,
      });
    }

    if(initAnimatedCabeza){
      gsap.to(".animate-cabeza", {
        duration: 2,
        delay: 0,
      });
      gsap.to(".animate-cabeza", {
        duration: 3,
        delay: 1,
      });
    }

  }, [initAnimatedHombreVolando, initAnimatedHombreOlas, initAnimatedHombreParado, initAnimatedHombreParado2, initAnimatedCabeza]);

  return (
    <ContObraComp>
      <BtnParrilla/>
      <BtnReplay onClick={() => fnReLaunchAll()}/>
      <div className={'columns-padding-no cont-obra-height'} onMouseOver={mouseOver} onMouseLeave={mouseLeaver}>
        <GlobalStyle/>
        <GlobalStylePage/>

        <Columns className="columns-responsive" isMultiline={false} isCentered={true}>
          <Column isSize={{mobile: 12, tablet: 12, desktop: 12, widescreen: 12}} className={'flex-center'}>
            <ContArrow>
              {showArrows && <ArrowNavTop toPage={arNav.top}/>}
            </ContArrow>
          </Column>
        </Columns>

        <Columns className="columns-responsive columns-obra-height flex-center" isMultiline={false} isCentered={true}>

          <Column isSize={{mobile: 1, tablet: 1, desktop: 1, widescreen: 1}} className={'flex-center'}>
            <ContArrow>
              {showArrows && <ArrowNavLeft toPage={arNav.left}/>}
            </ContArrow>
          </Column>

          <Column isSize={{mobile: 10, tablet: 10, desktop: 10, widescreen: 10}} className={'flex-center container-animated'}>
            {/*<Img fixed={data.file.childImageSharp.fixed} />*/}
            <Img className={'img-obra'} fluid={data.placeholderImage.childImageSharp.fluid}/>
            {fnAnimateHombreVolando()}
            {fnAnimateHombreOlas()}
            {fnAnimateHombreParado()}
            {fnAnimateHombreParado2()}
            {fnAnimateCabeza()}
            {fnSpeechBubbleMini()}
            {fnSpeechBubble()}
          </Column>

          <Column isSize={{mobile: 1, tablet: 1, desktop: 1, widescreen: 1}} className={'flex-center'}>
            <ContArrow>
              {showArrows && <ArrowNavRight toPage={arNav.right}/>}
            </ContArrow>
          </Column>

        </Columns>

        <Columns className="columns-responsive" isMultiline={false} isCentered={true}>
          <Column isSize={{mobile: 12, tablet: 12, desktop: 12, widescreen: 12}} className={'flex-center'}>
            <ContArrow>
              {showArrows && <ArrowNavBottom toPage={arNav.bottom}/>}
            </ContArrow>
          </Column>
        </Columns>
      </div>
    </ContObraComp>
  );
};

export default Page08Comp;
